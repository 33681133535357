import React, {useEffect, useRef, useState} from "react";
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts'

export default function ActivePlayers() {
    const [dbLoaded, setDbLoaded] = useState(false);
    const [dbPopData, setDbPopData] = useState([]);
    const [allPlays, setAllPlays] = useState(0);
    const [allPlayers, setAllPlayers] = useState(0);

    useEffect(() => {
        updateDbData();
        const dbI = setInterval(() => {
            updateDbData();
        }, 30000);
        return () => {
            clearInterval(dbI);
        };
    }, []);

    const updateDbData = () => {
        setDbLoaded(false)

        let headers = new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-User-Agent": "BNF3-UA-ADMIN-ONLY"
        });

        fetch('https://api.bnf3.com/api/backend/active-gamers', {headers: headers}).then(res => res.json())
        // fetch('http://localhost:3001/api/backend/active-gamers', {headers: headers}).then(res => res.json())
            // .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
            .then(res => {
                console.log("api response", res);
                // res data is {
                //     "active_2mins": "0",
                //     "active_5mins": "0",
                //     "active_15mins": "0",
                //     "active_30mins": "0",
                //     "active_60mins": "1"
                // }
                // make a array with only the values in reverse order
                const data = Object.values(res.data).reverse().map((d) => {
                    return Number(d);
                });

                setDbPopData(data);
                setAllPlays(res.all_plays);
                setAllPlayers(res.all_players);
                // console.log(data)


            })

    }

    return (
        <>

            <div style={{
                position: 'fixed',
                zIndex: 1000,
                bottom: 20,
                left: 20,
                fontSize: 50,
                backgroundColor: 'rgba(107,101,101,0.5)',
                padding: 10,
                color:'#f0f0f0'
            }}>Active Players
                <ApexChart data={dbPopData}  />
                <span style={{fontSize:30}}>Players: {allPlayers}</span>|
                <span style={{fontSize:30}}>Plays: {allPlays}</span>
            </div>
            {/*<div style={{*/}
            {/*    position: 'fixed',*/}
            {/*    zIndex: 1000,*/}
            {/*    top: 20,*/}
            {/*    right: 30,*/}
            {/*    fontSize: 50,*/}
            {/*    backgroundColor: 'rgba(107,101,101,0.5)',*/}
            {/*    padding: 10,*/}
            {/*    color:'#f0f0f0'*/}
            {/*}}>*/}
            {/*    Users: {dbPopData.count}*/}
            {/*</div>*/}
        </>
    );
}




class ApexChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "Players",
                data: this.props.data
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.1,

                    },
                },
                xaxis: {
                    categories: ['1h', '30min', '15min', '5min',  'Now'],
                    labels:{
                        style:{
                            colors: ['#f0f0f0','#f0f0f0','#f0f0f0','#f0f0f0','#f0f0f0'],
                            fontSize: '0.41em'
                        }
                    }
                },
                yaxis:{

                    labels:{
                        style:{
                            colors: ['#f0f0f0','#f0f0f0','#f0f0f0','#f0f0f0','#f0f0f0'],
                            fontSize: '0.41em'
                        }
                    }
                }
            },


        };
    }
    // re-render when props changed
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                series: [{
                    name: "Players",
                    data: this.props.data
                }]
            });
        }
    }



    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}
