import React from "react";
import CustomGlobe from "./CustomGlobe";
import WorldCities from "./WorldCities";
import Tvm from "./tvm";
import ActivePlayers from "./activePlayers";

export default function App() {

    const [loggedIn, setLoggedIn] = React.useState(false);
const [password, setPassword] = React.useState('');
    const handleLogin = () => {
        // use field data to post request to server
        // if successful, setLoggedIn(true)
        //get value of username and password


        fetch('https://api.bnf3.com/api/backend/login', {
            method: 'GET',
            headers: {
                'X-User-Agent': 'BNF3-UA-ADMIN-ONLY',
                'X-Password': password
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.data === true){
                setLoggedIn(true);
            }
        })

    }
    // try IP based auth
    React.useEffect(() => {
        fetch('https://api.bnf3.com/api/backend/login', {
            method: 'GET',
            headers: {
                'X-User-Agent': 'BNF3-UA-ADMIN-ONLY',
                'X-Password': 'just-check-my-ip'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.data === true){
                setLoggedIn(true);
            }
        })
    })


    if(!loggedIn){
        return(
            <div>
                <h1>Log in</h1>
                <input type="password" placeholder="Password"
                value={password} onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Log in</button>
            </div>
        )
    }
    return(
    <>
        <Tvm />
        <ActivePlayers />
        <WorldCities />
    </>
    )
}
