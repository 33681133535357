import React, {useEffect} from "react";
import {  useState, useRef, useCallback } from "react";
import { COUNTRIES_DATA } from "./data/countries_data";
import HEX_DATA from "./data/world_cities.json";
import Globe from "react-globe.gl";
import * as d3 from "d3";
const getRandomCountry = () => {
    return COUNTRIES_DATA[Math.floor(Math.random() * COUNTRIES_DATA.length)];
};


export default function WorldCities() {
    const globeEl = useRef();
    const country = getRandomCountry();
    const [selectedCountry, setSelectedCountry] = useState({
        lat: country.latitude,
        lng: country.longitude,
        label: country.name
    });
    const [dbLoaded, setDbLoaded] = useState(false);
    const [hex, setHex] = useState({ features: [] });

    const [popData, setPopData] = useState([]);
const [dbPopData, setDbPopData] = useState([]);

let countriesDisplayed=[];
let countriesHex=[];
const [usersRanking , setUsersRanking] = useState([]);

    useEffect(() => {
        updateDbData();
        const dbI=setInterval(() => {
            updateDbData();
        }, 15000);
            return () => {
                clearInterval(dbI);
            };
    }, []);

    const updateDbData = ()=>{
        setDbLoaded(false)
        let headers = new Headers({
            "Accept"       : "application/json",
            "Content-Type" : "application/json",
            "X-User-Agent"   : "BNF3-UA-ADMIN-ONLY"
        });
        fetch('https://api.bnf3.com/api/backend/country-pop',{headers:headers}).then(res => res.json())
            // .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
            .then( res=>{
                // console.log("api response",res.data);
                const data = res.data.map((d) => {
                    return {
                        country: d.country,
                        pop_max: Number(d.pop_max)
                    };
                });
                setDbPopData( data)

            })
    }


    useEffect(() => {
        if(!dbPopData || dbPopData.length===0) return;

        // console.log("api data",dbPopData);
        setDbLoaded(true);
    }, [dbPopData]);

    useEffect(() => {
        if(!dbLoaded) return;
        setUsersRanking([]);
        // only display the hex data which is in COUNTRIES_DATA
        const filteredHexData = HEX_DATA.features
        //     .filter((feature) =>
        //     COUNTRIES_DATA.some((country) => country.name === feature.properties.sov0name)
        // );
        // set pop_max to 0 if the country is not in dbPopData, otherwise set it to the value from dbPopData
        filteredHexData.forEach((feature) => {
            const country = dbPopData.find((pop) => pop.country === feature.properties.sov0name);
            // console.log("country",country)
            feature.properties.pop_max = country ? country.pop_max : 0;
            feature.properties.pop_min = country ? country.pop_max : 0;
            feature.properties.pop_other = country ? country.pop_max : 0;
            feature.properties.name = feature.properties.sov0name;
        });

        const prepPopData = filteredHexData.map((feature) => {
            return {
                lat: feature.properties.latitude,
                lng: feature.properties.longitude,
                pop_max: feature.properties.pop_max,
                name: feature.properties.sov0name
            };
        });



        // console.log("poopup data:", prepPopData);
        // update usersRanking in ascending order, with country names
        // remove countries with 0 population
        let nonZero = prepPopData.filter((d) => d.pop_max > 0);
        let usersRanking1 = nonZero.sort((a, b) => a.pop_max - b.pop_max).map((d) => d.name);
        // console.log("usersRanking", usersRanking1)

        // console.log('cities: ', filteredHexData)
        setUsersRanking(usersRanking1);
        setPopData(prepPopData);
        setHex(filteredHexData);
    }, [dbLoaded]);

    useEffect(() => {
        let interval;
    countriesDisplayed=[];
    countriesHex=[];
        interval = setInterval(() => {
            (async () => {
                const country = getRandomCountry();
                setSelectedCountry({
                    lat: country.latitude,
                    lng: country.longitude,
                    label: country.name
                });
            })();
        }, 8000); //Every 3 seconds
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        // globeEl.current.controls().autoRotate = true;
        // globeEl.current.controls().autoRotateSpeed = 0.2;

        const MAP_CENTER = { lat: 0, lng: 0, altitude: 1.5 };
        globeEl.current.pointOfView(MAP_CENTER, 0);
    }, [globeEl]);

    useEffect(() => {
        const countryLocation = {
            lat: selectedCountry.lat,
            lng: selectedCountry.lng,
            altitude: 1.5
        };

        globeEl.current.pointOfView(countryLocation, 0);
    }, [selectedCountry]);

    const weightColor = d3.scaleSequentialSqrt(d3.interpolateYlOrRd)
        .domain([0, 1e7]);

    useEffect(() => {
        // Auto-rotate
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.1;
    }, []);

    return (
        <Globe
            globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
            backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
            width={window.innerWidth}
            height={window.innerHeight}
            ref={globeEl}
            labelsData={hex}
            labelLat={d => d.properties.latitude}
            labelLng={d => d.properties.longitude}
            labelText={d =>{
                // console.log(d.properties)
                if(countriesDisplayed.includes(d.properties.adm0_a3)) {
                    return  ''
                }
                countriesDisplayed.push(d.properties.adm0_a3);
                return d.properties.adm0name+ ' ' + d.properties.pop_max}}
            // labelSize={d => Math.sqrt(d.properties.pop_max) * 0.8}
            labelSize={d =>{
                if(d.properties.pop_max === 0){
                    return 0
                }
                return usersRanking.lastIndexOf(d.properties.adm0_a3) *-1

            }}
            labelDotRadius={d => Math.sqrt(d.properties.pop_max) * 1e-1}
            labelColor={() => 'rgba(255, 165, 0, 0.75)'}
            labelResolution={2}


            hexBinPointsData={popData}
            hexBinPointWeight="pop_max"
            hexAltitude={d =>{
                // console.log(d.points[0].name)
                if(countriesHex.includes(d.points[0].name)) {
                    return  0
                }
                countriesHex.push(d.points[0].name);
                // console.log('user rankings', usersRanking)
                // console.log('index of: ', usersRanking.lastIndexOf(d.points[0].name), d.points[0].name, d.sumWeight ,d.sumWeight * (1e-2) )
                return usersRanking.lastIndexOf(d.points[0].name) * 0.01
                // return d.sumWeight * (1e-2)
            }}
            hexBinResolution={4}
            hexTopColor={d => weightColor(d.sumWeight)}
            hexSideColor={d => weightColor(d.sumWeight)}
            hexBinMerge={false}
            enablePointerInteraction={true}
        />
    );
}
