export const COUNTRIES_DATA = [
  {
    country: "ID",
    latitude: -6.17441770541,
    longitude: 106.829437621,
    name: "Indonesia"
  },
  {
    country: "MY",
    latitude: 2.91401979462,
    longitude: 101.70194698,
    name: "Malaysia"
  },
  {
    country: "PH",
    latitude: 14.6041589548,
    longitude: 120.982217162,
    name: "Philippines"
  },
  {
    country: "TH",
    latitude: 13.7499992055,
    longitude: 100.516644652,
    name: "Thailand"
  },
  {
    country: "JA",
    latitude: 34.7500352163,
    longitude: 135.460144815,
    name: "Japan"
  },
  {
    country: "VN",
    latitude: 21.0333272491,
    longitude: 105.8500142,
    name: "Vietnam"
  },
  {
    country: "KR",
    latitude: 37.5663490998,
    longitude: 126.999730997,
    name: "South Korea"
  },
  {
    country: "IN",
    latitude: 28.6000230092,
    longitude: 77.1999800201,
    name: "India"
  },
  {
    country: "BR",
    latitude: -14.235004,
    longitude: -51.92528,
    name: "Brazil"
  },
  {
    country: "DE",
    latitude: 52.5218186636,
    longitude: 13.4015486233,
    name: "Germany"
  },
  {
    country: 'UK',
    latitude: 51.509865,
    longitude: -0.11672184386,
    name: "United Kingdom"
  }
];
