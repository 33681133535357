import {useEffect, useRef, useState} from "react";

export default function Tvm() {
    const [dbLoaded, setDbLoaded] = useState(false);
    const [dbPopData, setDbPopData] = useState([]);
    const [conversion, setConversion] = useState(1000);

    useEffect(() => {
        updateDbData();
        const dbI = setInterval(() => {
            updateDbData();
        }, 15000);
        return () => {
            clearInterval(dbI);
        };
    }, []);

    const updateDbData = () => {
        setDbLoaded(false)

        let headers = new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-User-Agent": "BNF3-UA-ADMIN-ONLY"
        });
        try {
            fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then(res => res.json())
                .then(res => {
                    setConversion(res.USD);
                });
        }catch (e) {
        }
        fetch('https://api.bnf3.com/api/backend/tvm', {headers: headers}).then(res => res.json())
            // .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
            .then(res => {
                console.log("api response", res);
                setDbPopData(res)

            })

    }

    return (
        <>
            <div style={{
                position: 'fixed',
                zIndex: 1000,
                top: 20,
                left: 20,
                fontSize: 50,
                backgroundColor: 'rgba(107,101,101,0.5)',
                padding: 10,
                color:'#f0f0f0'
            }}>
                TVM: ${(dbPopData.tvm*conversion).toFixed(2)}
            </div>
            <div style={{
                position: 'fixed',
                zIndex: 1000,
                top: 20,
                right: 30,
                fontSize: 50,
                backgroundColor: 'rgba(107,101,101,0.5)',
                padding: 10,
                color:'#f0f0f0'
            }}>
                Users: {dbPopData.count}
            </div>
        </>
    );
}